import React from "react";
import "../meetus/meetus.css";
import { responsive, aboutUsData } from "../aboutUsData";
import AboutUsCard from "../aboutUsCard/aboutUsCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Fade, Zoom } from "react-awesome-reveal";

export default function MeetUs() {
  const aboutUs = aboutUsData.map((about) => (
    <AboutUsCard key={about.id} name={about.name} url={about.url} desc={about.description} />
  ));
  return (
    <div className="meetusCard">
      <div className="meetusCardContainer">
        <Zoom triggerOnce={true}>
          <div className="meetusHeading">
            <p className="meetusHeadingText">Meet Us</p>
            <p className="meetusHeadingDesc">
              Meet the Founder: Madalo Stanford Perenje
            </p>
            <p className="meetusHeadingDescmore">
              While Madalo Stanford Perenje initiated the spark with Byteburst,
              we wouldn't be where we are today without the incredible talents
              and dedication of our co-founders, Mayamiko Mlelemba and Omar
              Chibwana.
            </p>
          </div>
        </Zoom>

        <Fade direction="left" duration={2000} delay={500} triggerOnce={true}>
          <div className="slider">
            <Carousel responsive={responsive}>{aboutUs}</Carousel>
          </div>
        </Fade>
      </div>
    </div>
  );
}
