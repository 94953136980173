import React from 'react'
import './aboutUsCard.css'
import StarIcon from '@mui/icons-material/Star';

export default function AboutUsCard (props) {
  return (
    <div className='aboutUsCard'>
      <div className='aboutUsCardimage'>
      <img src={props.url} alt='' />
      </div>
      
      <div className='aboutUsCardDetails'>
        <p className='aboutUsCardName'>{props.name}</p>
        <p className='aboutUsCardDesc'>{props.desc}</p>
        <div className='aboutUsCardStars'>
          <StarIcon/>
          <StarIcon/>
          <StarIcon/>
          <StarIcon/>
          <StarIcon/>
        </div>
      </div>
    </div>
  )
}
