import React from "react";
import "./footer.css";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footerCard">
        <section className="footerCol">
          <h1>Address</h1>
          <p className="addressTxt">PMO malawi</p>
          <p className="addressTxt">P.O Box 2406</p>
          <p className="addressTxt">Blantyre</p>
          <p className="addressTxt">Malawi</p>
        </section>

        <section className="footerCol">
          <h1>Contact</h1>
          <ul>
            <li>+265 884 60 36 62</li>
            <li>+265 991 71 76 26</li>
            <li>pmoprojects@gmail.com</li>
          </ul>
        </section>

        {/* <section className="footerCol">
          <h1>Related Links</h1>
          <ul className="">
            <li className="">Home</li>
            <li className="">Meet Us</li>
            <li className="">About Us</li>
            <li className="">Contact Us</li>
          </ul>
        </section> */}

        <section className="footerCol">
          <h1>Our Work</h1>
          <ul>
            <li>
              <a href="https://www.byteburst.tech/">ByteBurst</a>
            </li>
          </ul>
        </section>
      </div>
      <p className="nameYeartxt">PMOpojects 2023</p>
    </div>
  );
}
