import React, { useState, useEffect } from "react";
import "./contactUs.css";
import { SocialIcon } from "react-social-icons";
import { Fade } from "react-awesome-reveal";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";

export default function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    document.body.style.overflow = "auto";
    setModalIsOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      message: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      openModal(); // Show modal immediately upon form submission

      setModalContent("Sending..."); // Initial content

      const response = await fetch("https://pmo-email-service.onrender.com/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log("Email sent successfully");
        setModalContent("Email sent successfully!");
      } else {
        console.log("Failed to send email");
        setModalContent("Failed to send email. Please try again.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      setModalContent("Failed to send email. Please try again.");
    }
  };

  useEffect(() => {
    // Add event listener to handle scrolling when modal is open
    const handleScroll = () => {
      if (modalIsOpen) {
        document.body.style.overflow = "hidden";
      }
    };

    // Attach the event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [modalIsOpen]);

  const closeButtonStyles = {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    background: "none", 
    color: "#e74c3c", 
    border: "none",
    
  };

  const customModalStyles = {
    content: {
      width: "50%",
      margin: "auto",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <div className="contactUs">
      <div className="contactUsText">
        <Fade triggerOnce={true} duration={1500}>
          <h1>Contact Us</h1>
          <p>
            We're here to assist you. If you have any questions, comments, or
            feedback, please don't hesitate to reach out to us. Our team is
            dedicated to providing you with the support you need. Feel free to
            use the contact information below, and we'll get back to you as soon
            as possible.
          </p>
        </Fade>
      </div>
      <div className="formContainer">
        <form onSubmit={handleSubmit}>
          <Fade className="form" triggerOnce={true} cascade={0.3}>
            <div className="formNameEmail">
              <input
                className="formNameSender"
                type="text"
                required
                placeholder="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              <input
                className="formNameSender"
                type="text"
                required
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <textarea
              name="message"
              required
              cols="30"
              rows="10"
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
            <input className="submitbtn" type="submit" value="Submit" />
          </Fade>
        </form>
        <p className="formOr">or</p>
        <Fade direction="right" delay={1000} triggerOnce={true}>
          <ul className="mediaUl">
            <li className="mediaLi">
              <SocialIcon url="https://twitter.com" />
            </li>
            <li className="mediaLi">
              <SocialIcon url="https://facebook.com" />
            </li>
            <li className="mediaLi">
              <SocialIcon url="https://instagram.com" />
            </li>
          </ul>
        </Fade>
      </div>

      {/* Modal for displaying success or failure pop-up */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customModalStyles}
      >
        <button onClick={closeModal} style={closeButtonStyles}>
          <FaTimes size={25} />
        </button>
        <h2 className="modalText">{modalContent}</h2>
      </Modal>
    </div>
  );
}
