import React, { useEffect, useState } from "react";
import "./scrollToTop.css";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

export default function ScrollToTop() {
  const [showBtn, setShowBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

 

  return (
    <>
      {showBtn && (
        <div onClick={scrollToTop} className="scrollToTop">
          <KeyboardDoubleArrowUpIcon fontSize="large"  color='error' />
        </div>
      )}
    </>
  );
}
