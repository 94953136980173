import madalostanfordPerenje from '../../media/people/IMG_4364.jpeg'
import image from '../../media/people/IMG_4365.jpeg'
import image2 from '../../media/people/353842841_6408022289246741_1448901784158107429_n.jpg'
import image3 from '../../media/people/IMG_4368.jpeg'
import image4 from '../../media/people/photo_2023-09-28_19-31-52.jpg'

export const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1024 },
    items: 2,
    slidesToSlide: 1
  },
  desktop: {
    breakpoint: { max: 1024, min: 800 },
    items: 2,
    slidesToSlide: 1
  },
  tablet: {
    breakpoint: { max: 800, min: 464 },
    items: 1,
    slidesToSlide: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
}

export const aboutUsData = [
  {
    id:1,
    url: madalostanfordPerenje,
    name: 'Madalo Stanford Perenje',
    description:
      'Madalo Stanford Perenje, our founder, is the visionary behind the inception of Byteburst. His relentlessdedication and groundbreaking ideas laid the foundation for what would become PMO PROJECTS. As the   creator of our first project, Byteburst, he holds the title of founder and continues to lead us with his   visionary insights.'
  },
  {
    id:2,
    url: image,
    name: 'Omar Chibwana',
    description:
      'Omar is the driving force behind our technical prowess and innovation. As a co-founder, Omar has played a pivotal role in crafting our technological vision. Their relentless pursuit of excellence and dedication to    pushing the boundaries of whats possible have been key factors in our success.'
  },

  {
    id:3,
    url: image3,
    name: 'Mayamiko Mlelemba',
    description:
      'Mayamiko is the creative genius whose expertise and insights have been instrumental in our growth. As    a co-founder, Mayamiko has brought a wealth of knowledge and passion to our team. Their dedication to    our mission and unwavering commitment to delivering top-notch solutions have shaped PMO PROJECTS    into what it is today.'
  },

  {
    id:4,
    url: image4,
    name: 'Melvin Kalidozo',
    description: 'Mervin Kalidozo - UI/UX Designer: Mervin ensures our software not only functions flawlessly but    also provides an exceptional user experience'
  },

  {
    id:5,
    url: image2,
    name: 'Bandron Njiragoma',
    description:
      'Bandron Njiragoma - Chief of Marketing: Bandron is the mastermind behind making our products shine in the digital world.'
  }
]
