import React from "react";
import "./aboutUs.css";
import { Zoom, Fade } from "react-awesome-reveal";

export default function AboutUs() {
  return (
    <div className="aboutus">
      <div className="aboutUsDesc">
        <Zoom delay={500} duration={1500} triggerOnce={true}>
          <h1 className="aboutUsDesctext">About Us</h1>
          <div className="aboutUsDescText">
            <p>
              PMO PROJECTS was born from a spark of innovation and a passion for
              technology. Founded by Madalo Stanford Perenje, the driving force
              behind Byteburst, our story is one of collaboration, vision, and
              the relentless pursuit of excellence.
            </p>
          </div>
        </Zoom>

        <div className="aboutUsDescMore">
          <Fade delay={1000} duration={1500}  direction="left" triggerOnce={true} className="aboutUsDescCol">
            <section >
              <h1>Our Vision: Innovation with a Purpose</h1>
              <p>
                At PMO PROJECTS, we have a clear vision. We aim to be a leading
                software development company that caters to the unique needs of
                clients. While we currently specialize in file sharing and video
                conversion services with Byteburst, we have grand plans for the
                future.
              </p>
            </section>
          </Fade>

          <Zoom delay={1000} duration={1500}  triggerOnce={true} className="aboutUsDescCol">
            <section >
              <h1>Together, We Thrive</h1>
              <p>
                While titles like "founder" or "co-founder" help define roles,
                at PMO PROJECTS, we are more than titles; we are a team. Madalo
                Stanford Perenje, Mayamiko Mlelemba, and Omar Chibwana, together
                with our creative and marketing minds, Mervin Kalidozo and
                Bandron Njiragoma, form a cohesive unit that embodies the spirit
                of innovation. We celebrate the unique strengths and
                contributions of each team member, recognizing that it's our
                collective effort that drives PMO PROJECTS forward. As we
                continue to explore new horizons and expand our offerings, we do
                so with deep gratitude for the talented individuals who make up
                our team.
              </p>
            </section>
          </Zoom>

          <Fade delay={1000} duration={1500}  direction="right" triggerOnce={true} className="aboutUsDescCol">
            <section >
              <h1 className="whatweoffer">What We Offer</h1>
              <ol>
                <li>
                  <p>Custom Software Development</p>
                  <p>
                    We craft tailored software solutions to empower individuals
                    and businesses.
                  </p>
                </li>

                <li>
                  <p>Future Expansion</p>
                  <p>
                    Stay tuned for storage and hosting services as we continue
                    to grow.
                  </p>
                </li>

                <li>
                  <p>Diverse Software</p>
                  <p>
                    We believe in the power of both paid and free software,
                    offering options for everyone.
                  </p>
                </li>
              </ol>
            </section>
          </Fade>
        </div>
      </div>
    </div>
  );
}
