import React, { useRef } from "react";
import "./home.css";
import AboutUs from "../aboutUs/aboutUs";

import ContactUs from "../contactUs/contactUs";
import Footer from "../footer/footer";
import MeetUs from "../aboutUs/meetus/meetus";
import { Slide, Zoom } from "react-awesome-reveal";
import { FaBars, FaTimes } from "react-icons/fa";
import ScrollToTop from "../scrollToTop/scrollToTop";

export default function Home() {
  const navref = useRef();

  const hideNav = () => {
    navref.current.classList.toggle("responsive_nav");
  };

  const showNavbar = () => {
    navref.current.classList.toggle("responsive_nav");
  };

  const meetUs = useRef(null);
  const aboutUs = useRef(null);
  const contactUs = useRef(null);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <div className="home">
      <ScrollToTop />
      <div className="welcome">
        <div className="heading">
          <ul ref={navref} className="navbar ">
            <li
              onClick={() => {
                hideNav();
                scrollToSection(meetUs);
              }}
              className="navbarItem"
            >
              Meet Us
            </li>
            <li
              onClick={() => {
                hideNav();
                scrollToSection(aboutUs);
              }}
              className="navbarItem"
            >
              About Us
            </li>
            <li
              onClick={() => {
                hideNav();
                scrollToSection(contactUs);
              }}
              className="navbarItem"
            >
              Contact Us
            </li>
            <button className="nav_btn nav_close_btn" onClick={showNavbar}>
              <FaTimes size={25} />
            </button>
          </ul>

          <div className="nav_btn_wrapper">
            <button className="nav_btn" onClick={showNavbar}>
              <FaBars size={25} />
            </button>
          </div>
        </div>

        <div className="welcomSection">
          <Slide delay={1000} triggerOnce={true}>
            <p className="welcomSectionHeading">PMO PROJECTS</p>
          </Slide>

          <Zoom delay={1500} triggerOnce={true}>
            <p className="welcomSectionDesc">
              We have a clear vision. We aim to be a leading software
              development company that caters to the unique needs of Clients.We
              have a well structured team and strong ethics.we specialize in building custom tailored software for businesses and individuals solutions
            </p>
          </Zoom>

          <Zoom delay={2500} triggerOnce={true}>
            <p className="welcomSectionMoto">Innovation with a Purpose</p>
          </Zoom>
        </div>
      </div>

      <div ref={meetUs} className="MeetUsContainer">
        <MeetUs />
      </div>

      <div ref={aboutUs} className="aboutusContainer">
        <AboutUs />
      </div>

      <div ref={contactUs} className="contactContainer">
        <ContactUs />
      </div>

      <div className="footerContainer">
        <Footer />
      </div>
    </div>
  );
}
